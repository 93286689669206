import React, { useState } from 'react';
import '../styles/long1.css';
import '../styles/newForm.css';
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import $ from "jquery";

const IndexPage = () => {
    function connectIterable(){
        const payload = {
            "firstName": document.getElementById('first_name').value,
            "lastName": document.getElementById('last_name').value,
            "phoneNum": document.getElementById('phone').value,
            "plateNumber": document.getElementById('license_plate_num').value,
            "plateState": document.getElementById('license_plate_state').value,
            "tagType": document.getElementById('toll_tag_type').value,
            "tagNumber": document.getElementById('toll_tag_num').value
            }
        const email = document.getElementById('email').value
        var emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailReg.test(email)) {
            $( ".error-text" ).css('display','block');
        } 
       else{
            $( ".error-text" ).css('display','none');
            const updateUserData = `https://api.iterable.com/api/users/update`
            $.ajax({
                url: updateUserData,
                method: "POST",
                contentType: "application/json",
                headers: {
                    'Api_key': '3a1d91bb8a534022af114c94b9239beb'
                },
                data:JSON.stringify({
                    "email": email,
                    "dataFields": payload
                }),
                dataType: 'json',
                success: function (data) {
                    $(".confirmation").show();
                    $("#registerForm").hide();
                }
            });
        }
    }
    const logData  = async (event) =>{
        event.preventDefault();
        if (document.getElementById('valid_checkbox').checked) {
            $( ".valid_checkbox_error" ).hide();
            var val = document.getElementById('toll_tag_type').value;
            var tollTag = document.getElementById('toll_tag_num').value.toUpperCase();
            if (val === "ntta_tolltag") {
            if (tollTag.includes(".")) {
                var spilt = tollTag.split(".");
                if (!(spilt[0].length === 3 && (spilt[0] === "DNT" || spilt[0] === "DFW") && spilt[1].length >= 8 && spilt[1].length <= 9 && /^\d+$/.test(spilt[1]))) {
                    $( ".toll_tag_error" ).show();
                    setTimeout(function() {
                        $( ".toll_tag_error" ).hide();
                    }, 3000);
                    return false;
                }else{
                    connectIterable();
                }
            } else {
                $( ".toll_tag_error" ).show();
                setTimeout(function() {
                    $( ".toll_tag_error" ).hide();
                }, 3000);
                return false;
            }
        } else if (val === "txtag") {
            if (tollTag.includes(".")) {
                var spilt = tollTag.split(".");
                if (!(spilt[0].length === 3 && spilt[0] === "TEX" && spilt[1].length === 8 && /^\d+$/.test(spilt[1]))) {
                    $( ".toll_tag_error" ).show();
                    setTimeout(function() {
                        $( ".toll_tag_error" ).hide();
                    }, 3000);
                    return false;
                }else{
                    connectIterable();
                }
            } else {
                $( ".toll_tag_error" ).show();
                setTimeout(function() {
                    $( ".toll_tag_error" ).hide();
                }, 3000);
                return false;
            }
        } else if (val === "ez_tag") {
            if (!tollTag.includes(".")) {
                var firstFour = tollTag.slice(0, 4);
                if (!(firstFour === "HCTR" && tollTag.match(/\d+/)[0].length === 8 && tollTag.length === 12)) {
                    $( ".toll_tag_error" ).show();
                    setTimeout(function() {
                        $( ".toll_tag_error" ).hide();
                    }, 3000);
                    return false;
                }else{
                    connectIterable();
                }
            } else {
                $( ".toll_tag_error" ).show();
                setTimeout(function() {
                    $( ".toll_tag_error" ).hide();
                }, 3000);
                return false;
            }
        }
        } 
        else {
            $( ".valid_checkbox_error" ).show();
        }
    }
    function changeplh() {
        console.log("in change");
        var sel = document.getElementById("toll_tag_type");
        var textbx = document.getElementById("toll_tag_num");
        var indexe = sel.selectedIndex;
    
        if (indexe == 1) {
            $("#toll_tag_num").attr("placeholder", "(e.g. DNT.12345678 or DFW.12345678)");
    
        }
        if (indexe == 2) {
            $("#toll_tag_num").attr("placeholder", "(e.g. TEX.12345678)");
        }
        if (indexe == 3) {
            $("#toll_tag_num").attr("placeholder", "(e.g. HCTR12345678)");
        }
    }

    const [inputVal, setValue] = useState("");
    const upperCaseF = (event) => {
      setValue(event.target.value.toUpperCase());
    };
    const [inputVal2, setinputVal2] = useState("");
    const upperCaseF2 = (event) => {
        setinputVal2(event.target.value.toUpperCase());
      };
    return(
  <Layout>
    <SEO title="MyTEXpress " />
  <div className="grid-container long1-bg-image">
        <div className="left_space"></div>
        <div className="long1-main">
            <div className="main1">
                    <div className="logo-div">
                    <a aria-current="page" className="logo" href="/"><div className="logo-image"></div>
                    </a></div>
                    <div className="nav-belt header-section1">
                        <div className="header-section1-left">
                            <h3 className="header-title">Create your MyTEXpress profile</h3>
                        </div>
                        <div className="header-section1-right">
                            <p className="header-text">Be among the first to know about upcoming discounts and offers on the LBJ, NTE &amp; NTE 35W TEXpress Lanes.</p>
                        </div>
                    </div>
            </div>
            <div className="main2">
            <div className="form-section">
                    <div className="wp-block-column grid-column-parent long1-parents">
                        <h3 className="form-section-header">Benefits of signing up:</h3>
                        <div className="vector-img"></div>
                        <p className="form-section-title">Offers and Discounts</p>
                        <p className="form-section-text">Determine your eligibility to save time and money on the LBJ, NTE &amp; NTE 35W TEXpress Lanes</p>
                        <div className="alerts-img"></div>
                        <h3 className="form-section-title">Updates and Traffic Alerts</h3>
                        <p className="form-section-text">Make sure you're the first to know about any construction updates or traffic alerts along your TEXpress Lanes route.</p>
                        <a href='https://www.texpresslanes.com/faq-page' target='_blank'><p className="form-section-text">Click here for Frequently Asked Questions about MyTEXpress</p></a>
                    </div>
                    <div className="wp-block-column grid-column-children form-section-right">
                        <form id="registerForm" onSubmit={logData} >
                            <div className="name_field">
                                <div className="first_name_field">
                                    <input type="text" name="first_name" id="first_name" placeholder="First Name" required />
                                </div>
                                <div className="last_name_field">
                                    <input type="text" name="last_name" id="last_name" placeholder="Last Name" required />
                                </div>
                            </div>
                            <div className="email_field">
                                <input type="text" name="email" id="email" placeholder="Email" required />
                            </div>
                            <label class="error-text" for="bluecoreEmailCaptureEmail">Valid email is required</label>
                        <div className="phone_field">
                                <input type="text" name="phone" id="phone" placeholder="Phone Number" required maxLength="20" minLength="1" onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault(); }}} />
                        </div>
                        {/* <label class="phone-error-text" for="bluecoreEmailCaptureEmail">Invalid phone number</label> */}
                        <div className="license_plate_num_sec">
                            <div className="license_plate_num_field">
                                    <input type="text" name="license_plate_num" id="license_plate_num" placeholder="License Plate Number" maxLength="15" minLength="1" required value={inputVal} onChange={upperCaseF} onKeyPress={(event) => {if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(event.key)) {event.preventDefault(); }}}/>
                            </div>
                            <div className="license_plate_num_content">
                                    <p className="license_plate_text">Must match with respective vehicle's toll tag number below</p>
                                </div>
                        </div>
                        {/* <label class="license-error-text" for="bluecoreEmailCaptureEmail">Invalid license plate</label> */}
                        <select id="license_plate_state" name="license_plate_state" required>
                                <option value="" hidden >License Plate State</option>  
                                <option value="tx">TX</option>  
                                <option value="al">AL</option>  
                                <option value="ak">AK</option>  
                                <option value="az">AZ</option>  
                                <option value="ar">AR</option>  
                                <option value="ca">CA</option>  
                                <option value="co">CO</option>  
                                <option value="ct">CT</option>  
                                <option value="de">DE</option>  
                                <option value="dc">DC</option>  
                                <option value="fl">FL</option>  
                                <option value="ga">GA</option>  
                                <option value="hi">HI</option>  
                                <option value="al">ID</option>  
                                <option value="il">IL</option>  
                                <option value="in">IN</option>  
                                <option value="ia">IA</option>  
                                <option value="ks">KS</option>  
                                <option value="ky">KY</option>  
                                <option value="la">LA</option>  
                                <option value="me">ME</option>  
                                <option value="md">MD</option>  
                                <option value="ma">MA</option>  
                                <option value="mi">MI</option>  
                                <option value="mn">MN</option>  
                                <option value="ms">MS</option>  
                                <option value="mt">MT</option>  
                                <option value="ne">NE</option>  
                                <option value="nv">NV</option>  
                                <option value="nh">NH</option>   
                                <option value="nj">NJ</option>  
                                <option value="nm">NM</option>  
                                <option value="ny">NY</option>  
                                <option value="nc">NC</option>  
                                <option value="nd">ND</option>  
                                <option value="oh">OH</option>  
                                <option value="ok">OK</option>  
                                <option value="or">OR</option>  
                                <option value="pa">PA</option>  
                                <option value="ri">RI</option>  
                                <option value="sc">SC</option>  
                                <option value="sd">SD</option>  
                                <option value="tn">TN</option>  
                                <option value="ut">UT</option>  
                                <option value="vt">VT</option>  
                                <option value="va">VA</option>  
                                <option value="wa">WA</option>  
                                <option value="wv">WV</option>  
                                <option value="wi">WI</option>  
                                <option value="wy">WY</option>    
                            </select>
                            <select id="toll_tag_type" onChange={changeplh} className="form-control">
                                        <option value="" hidden>Toll Tag Type</option>  
                                            <option value="ntta_tolltag">NTTA TollTag</option>  
                                            <option value="txtag">TxTag</option>  
                                            <option value="ez_tag">EZ TAG</option>  
                                </select>
                                <div>
                                <div className="toll_tag_sec">
                                    <div className="toll_tag_field">
                                            <input type="text" name="toll_tag_num" id="toll_tag_num" placeholder="Toll Tag Number" required value={inputVal2} onChange={upperCaseF2} />
                                    </div>
                                    <div className="toll_tag_content">
                                            <p className="toll_tag_text">Must have valid toll tag with respective license plate number</p>
                                        </div>
                                </div>
                                <p className="toll_tag_error">Please enter a valid Toll Tag Number in correct format.</p>
                                </div>
                            <div className="policy_checkbox">
                                <input type="checkbox" id="valid_checkbox" name="checkbox" />
                                <label className="policy_text">By proceeding, you agree to our <a href="https://www.texpresslanes.com/privacy-policy/" target="_blank" className="privacy_policy">Privacy Policy</a></label>
                            </div>
                            <p className="valid_checkbox_error">Checkbox is required</p>
                            <button name="submit" type="submit" id="form-submit-btn">BECOME A MEMBER</button>
                        </form>
                        <div className="confirmation">
                            <p className="confirmation-message">Thanks for signing up!</p>
                        </div>
                    </div>
            </div>
            </div>
            <div className="main3">
                <div className="bot-section">
                    <p className="bot-section-text">You can use any toll tag from Texas (e.g. NTTA TollTag, TxTag or EZTAG) on the TEXpress Lanes. In addition, Oklahoma's PIKEPASS can also be used.</p>
                    <p className="bot-section-text">Need help finding your toll tag number?</p>
                    <p className="bot-section-text">Here are two ways you can locate your toll tag number:</p>
                    <div className="longForm-bot-img"></div>
                    <p className="bot-section-text text-under-img">Refer to the images above to find the information printed on your toll tag (on your vehicle's windshield)</p>
                    <p className="bot-section-text text-under-img">The toll tag number is printed on the side facing the driver in the vehicle. </p>
                    <p className="bot-section-text text-under-img toll-tag-text">You can also find your toll tag number by logging into your respective toll tag account.</p>
                    <a href="https://csc.ntta.org/olcsc/" target="_blank"><p className="bot-link-text">Visit My TollTag Account</p></a>
                    <a href="https://www.txtag.org/txtagstorefront/en/" target="_blank"><p className="bot-link-text">Visit My TxTag Account</p></a>
                    <a href="https://www.hctra.org/Login" target="_blank"><p className="bot-link-text">Visit My EZ TAG Account</p></a>
                </div>
            </div>
        </div>  
        <div className="right_space"></div>
         <div className="footer">
            <div className="footer1"><a href="https://www.facebook.com/TEXpressLanes" target="_blank"><img className="fb-icon"
  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"/></a> | <a href="https://twitter.com/texpresslanes" target="_blank"><img className="twitter-icon"
  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"/></a> |  <a href="https://www.texpresslanes.com/privacy-policy" target="_blank">Privacy Policy</a> │ ©2020 LBJ/NTE/NTE 35W TEXpress Lanes | All Rights Reserved</div>
        </div>
</div>
</Layout>
)};

export default IndexPage;
